/* Root element */
.json-document {
  padding: 1em 2em;
}

/* Syntax highlighting for JSON objects */
ul.json-dict, ol.json-array {
  list-style-type: none;
  margin: 0 0 0 1px;
  border-left: 1px dotted #ccc;
  padding-left: 2em;
}
.json-string {
  color: #0B7500;
}
.json-literal {
  color: #1A01CC;
  font-weight: bold;
}

/* Toggle button */
a.json-toggle {
  position: relative;
  color: inherit;
  text-decoration: none;
}
a.json-toggle:focus {
  outline: none;
}
a.json-toggle:before {
  font-size: 1.1em;
  color: #c0c0c0;
  content: "\25BC"; /* down arrow */
  position: absolute;
  display: inline-block;
  width: 1em;
  text-align: center;
  line-height: 1em;
  left: -1.2em;
}
a.json-toggle:hover:before {
  color: #aaa;
}
a.json-toggle.collapsed:before {
  /* Use rotated down arrow, prevents right arrow appearing smaller than down arrow in some browsers */
  transform: rotate(-90deg);
}

/* Collapsable placeholder links */
a.json-placeholder {
  color: #aaa;
  padding: 0 1em;
  text-decoration: none;
}
a.json-placeholder:hover {
  text-decoration: underline;
}
